
import {
  _support,
  isBrowserEnv,
  Queue,
  SDK_VERSION,
  SDK_NAME,
  BASETYPE,
  EMethods,
  OTHERTYPE,
  OPTIONS,
  isEmpty,generateUUID,getLocationHref,validateOption
} from '@wewoo/utils';
import { ReportData, InitOptions } from '@wewoo/interface';



export class Report {
  queue: Queue = new Queue(); // 消息队列
  sysCode = '';
  errorDsn = '';
  userId = '';
  uuid: string; // 每次页面加载的唯一标识
  beforeDataReport: any; // 上报数据前的hook
  getUserId: any; // 用户自定义获取userId的方法
  useImgUpload = false;
  constructor() {
    this.uuid = generateUUID();
  }
  beacon(url: string, data: any): boolean {
    return navigator.sendBeacon(url, JSON.stringify(data));
  }
  imgRequest(data: ReportData, url: string): void {
    const requestFun = () => {
      const img = new Image();
      const spliceStr = url.indexOf('?') === -1 ? '?' : '&';
      img.src = `${url}${spliceStr}data=${encodeURIComponent(JSON.stringify(data))}`;
    };
    this.queue.addFn(requestFun);
  }

  async beforePost(this: any, data: ReportData): Promise<ReportData | boolean> {
    let reportData = this.getTransportData(data);
    if (typeof this.beforeDataReport === BASETYPE.FUNCTION) {
      reportData = this.beforeDataReport(reportData);
      if (!reportData) return false;
    }
    return reportData;
  }
  async xhrPost(data: ReportData, url: string): Promise<void> {
    const requestFun = () => {
      fetch(`${url}`, {
        method: EMethods.Post,
        body: JSON.stringify(data),
        headers: {
          'Content-Type': OTHERTYPE.CONTENTTYPE,
        },
      });
    };
    this.queue.addFn(requestFun);
  }
  // 获取用户信息
  getAuthInfo() {
    return {
      userId: this.userId || this.getAuthId() || '',
      sdkVersion: SDK_VERSION,
      sysCode: this.sysCode,
    };
  }
  getAuthId(): string | number {
    if (typeof this.getUserId === BASETYPE.FUNCTION) {
      const id = this.getUserId();
      if (typeof id === BASETYPE.STRING || typeof id === BASETYPE.NUMBER) {
        return id;
      } else {
        console.error(`${SDK_NAME} ${OPTIONS.USERID}: ${id} 期望 ${BASETYPE.STRING} 或 ${BASETYPE.NUMBER} 类型，但是传入 ${typeof id}`);
      }
    }
    return '';
  }

  getTransportData(data: any): ReportData {
    const info = {
      ...data,
      ...this.getAuthInfo(),
      uuid: this.uuid,
      pageUrl: getLocationHref(),
    };
    return info;
  }

  isSdkTransportUrl(targetUrl: string): boolean {
    let isSdkDsn = false;
    if (this.errorDsn && targetUrl.indexOf(this.errorDsn) !== -1) {
      isSdkDsn = true;
    }
    return isSdkDsn;
  }


  bindOptions(options: InitOptions): void {
    const { dsn, sysCode, beforeDataReport, userId, getUserId, useImgUpload } = options;

    validateOption(sysCode, OPTIONS.APIKEY, BASETYPE.STRING) && (this.sysCode = sysCode);
    validateOption(dsn, OPTIONS.DNS, BASETYPE.STRING) && (this.errorDsn = dsn);
    validateOption(userId, OPTIONS.USERID, BASETYPE.STRING) && (this.userId = userId || '');
    validateOption(useImgUpload, OPTIONS.USEIMGUPLOAD, BASETYPE.BOOLEAN) &&
      (this.useImgUpload = useImgUpload || false);
    validateOption(beforeDataReport, OPTIONS.BEFOREDATAREPORT, BASETYPE.FUNCTION) &&
      (this.beforeDataReport = beforeDataReport);
    validateOption(getUserId, OPTIONS.GETUSERID, BASETYPE.FUNCTION) && (this.getUserId = getUserId);
  }

  async send(data: ReportData) {

    const dsn = this.errorDsn;
    if (isEmpty(dsn)) {
      console.error(`${SDK_NAME}: 请配置${OPTIONS.DNS}`);
      return;
    }
    const result = (await this.beforePost(data)) as ReportData;
    if (isBrowserEnv && result) {

      if(result.fileName){
        if(!result.fileName.includes('http')){
          return false;
        }
      }else{
        result.fileName = location.href;
      }
      if(!result.userId){
        return false;
      }
      const value = this.beacon(dsn, result);
      if (!value) {
        return this.useImgUpload ? this.imgRequest(result, dsn) : this.xhrPost(result, dsn);
      }
    }
  }
}
export const reportData = _support.reportData || (_support.reportData = new Report());

