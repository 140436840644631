import { setFlag, _support } from './global';
import { EVENTTYPES,BASETYPE,OTHERTYPE } from './enum';

import { Callback, IAnyObject } from '@wewoo/interface';
import {version} from '../../../error/package.json';



export const SDK_NAME = OTHERTYPE.SKDNAME;
export const SDK_VERSION = version;


export function setSilentFlag({
  silentXhr = true,
  silentFetch = true,
  silentError = true,
  silentUnhandledrejection = true,
}): void {
  setFlag(EVENTTYPES.XHR, !silentXhr);
  setFlag(EVENTTYPES.FETCH, !silentFetch);
  setFlag(EVENTTYPES.ERROR, !silentError);
  setFlag(EVENTTYPES.UNHANDLEDREJECTION, !silentUnhandledrejection);
}




export function hashMapExist(hash: string): boolean {
  const exist = _support.errorMap.has(hash);
  if (!exist) {
    _support.errorMap.set(hash, true);
  }
  return exist;
}



export function nativeTryCatch(fn: any, errorFn?: any): void {
  try {
    fn();
  } catch (err:any) {
    if (errorFn) {
      errorFn(err.message);
    }
  }
}




export function replaceAop(
  source: IAnyObject,
  name: string,
  replacement: Callback,
  isForced = false
) {
  if (source === undefined) return;
  if (name in source || isForced) {
    const original = source[name];
    const wrapped = replacement(original);
    if (typeof wrapped === BASETYPE.FUNCTION) {
      source[name] = wrapped;
    }
  }
}
